import { Component, OnInit } from '@angular/core';
import { NgForm, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { ModalController, MenuController, NavController } from '@ionic/angular';

import { AlertController } from '@ionic/angular';

@Component({
    selector: 'app-forgot',
    templateUrl: './forgot.page.html',
    styleUrls: ['./forgot.page.scss'],
})

export class ForgotPage implements OnInit {
    forgotForm: FormGroup;
    formTry;
    disableSubmit;
    submitText;

    constructor(
        private modalController: ModalController,
        public formBuilder: FormBuilder,
        private authService: AuthService,
		private alertController: AlertController
    ) {
        this.formTry = '';
        this.disableSubmit = false;
        this.submitText = 'Reset Password';

        this.forgotForm = formBuilder.group({
            email: ['', Validators.compose([Validators.maxLength(70), Validators.pattern('^[_A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$'), Validators.required])]
        });
    }

    ngOnInit() {
    }

    dismiss() {
        this.modalController.dismiss();
    }



    async forgotPassword(form: FormGroup) {

        const alert = await this.alertController.create({
            header: 'Resetting Password',
            message: 'You will be sent an email with your new password that you may use to login. If you do not recieve the email, try checking your spam folder',
            buttons: [
                {
                    text: 'Ok',
                    handler: () => {
                        this.formTry = 'submitted';

                        if (this.forgotForm.valid) {
                            this.disableSubmit = true;
                            this.submitText = 'Sending email...';
                            this.authService.forgotPassword(form.value.email).subscribe((data) => {
                                this.modalController.dismiss();
                                this.disableSubmit = false;
                                this.submitText = 'Reset Password';
                            });
                        }
                    }
                }
            ]
        });
    
        await alert.present();

    }

}
